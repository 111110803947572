import Big from 'big.js';
import uniqBy from 'lodash/uniqBy';
import keys from 'lodash/keys';
import { getJSONObjectFromLocalStorage } from '../localStorageHelpers';
import {
  CURRENCY,
  EMPTY_VALUES,
  DEFAULT_CURRENCY_PRECISION,
  ORDER_TYPES,
  BIG_ZERO
} from '../../constants/Core';
import { isBigNumber } from '../core';

export const getWhiteListedOrderTypes = orderTypes => {
  const allowedOrderTypes = [ORDER_TYPES.LIMIT, ORDER_TYPES.STOP_LIMIT];

  return orderTypes.filter(orderType =>
    allowedOrderTypes.includes(orderType.value)
  );
};

export const normalizeConfig = configs => {
  let statusInfo;
  let fundsStatusInfo;
  let appLaunchPopup;
  let withdrawalModesStatus = {};
  let depositModesStatus = {};
  let inrCurrency = {};
  const markets = {};
  const currencies = {};
  const allQuoteMarkets = [];
  const p2pMarkets = {};
  const otcMarkets = {};
  const sources = {};
  let orderTypes = [];
  let defaultP2PMarket;
  let defaultOTCMarket;

  if (configs.appLaunchPopup && configs.appLaunchPopup.web) {
    appLaunchPopup = configs.appLaunchPopup.web;
  }

  if (configs.statusInfo && configs.statusInfo.web) {
    statusInfo = configs.statusInfo.web;
  }

  if (configs?.fundsStatusInfo?.web) {
    fundsStatusInfo = configs.fundsStatusInfo.web;
  }

  if (configs.currencies) {
    configs.currencies.forEach(currency => {
      currency.symbol = currency.symbol || currency.type.toUpperCase();
      if (currency.symbol.length > 1) {
        currency.symbol = `${currency.symbol} `;
      }
      currencies[currency.type] = currency;
    });

    inrCurrency = currencies[CURRENCY.INR];

    if (inrCurrency) {
      withdrawalModesStatus = inrCurrency.withdrawalModes;
      depositModesStatus = inrCurrency.depositModes;
    }

    if (configs.orderTypes) {
      orderTypes = configs.orderTypes.map(orderType => ({
        value: orderType.type,
        label: orderType.name,
        info: orderType.info
      }));
    }

    configs.markets.forEach(market => {
      market.searchString = `*/${market.baseMarket} ${currencies[market.baseMarket].name}`.toLowerCase();
      market.orderTypes = getWhiteListedOrderTypes(
        market.unsupportedOrderTypes
          ? orderTypes.filter(
              orderType =>
                !market.unsupportedOrderTypes.includes(orderType.value)
            )
          : orderTypes
      );
      markets[market.type] = market;
      allQuoteMarkets.push(market.quoteMarket);
    });

    configs.p2pMarkets.forEach(p2pMarket => {
      p2pMarkets[`${p2pMarket.type}`] = p2pMarket;
      if (!defaultP2PMarket) {
        defaultP2PMarket = p2pMarket.type;
      }
    });

    configs.otcMarkets &&
      configs.otcMarkets.forEach(otcMarket => {
        const marketType =
          (otcMarket?.type && otcMarket?.type) ||
          otcMarket.baseMarket + otcMarket.quoteMarket;
        if (marketType) {
          // take the base data from the general markets, but override OTC market params in that
          otcMarkets[`${marketType}`] = {
            ...markets[marketType],
            ...otcMarket,
            id: marketType,
            base: otcMarket.baseMarket,
            quote: otcMarket.quoteMarket
          };
          if (!defaultOTCMarket) {
            defaultOTCMarket = marketType;
          }
        }
      });
  }

  if (configs.sources) {
    configs.sources.forEach(source => {
      sources[source.code] = source;
    });
  }

  return {
    statusInfo,
    fundsStatusInfo,
    appLaunchPopup,
    withdrawalModes: withdrawalModesStatus,
    depositModes: depositModesStatus,
    currencies,
    defaultUserSettings: configs.defaultUserSettings,
    markets,
    p2pMarkets,
    otcMarkets,
    defaultP2PMarket,
    defaultOTCMarket,
    preferredQuoteCurrency: configs.preferredQuoteCurrency,
    quoteCurrencies: allQuoteMarkets.filter(
      (item, i, ar) => ar.indexOf(item) === i
    ),
    sources,
    tradingFeeConsent: configs?.tradingFeeConsent
  };
};

export const getIconURLFromCurrency = currency => {
  const config = getJSONObjectFromLocalStorage('config');
  if (config && config.currencies) {
    const currencyObject = config.currencies[currency.toLowerCase()];
    return currencyObject && currencyObject.icon.replace('<SIZE>', 84);
  }
  return null;
};

export const mergeMarketWithSeparator = (pair, separator) => {
  const pairArray = pair.split('');
  pairArray.splice(pairArray.indexOf(separator), 1);
  return pairArray.join('').toLowerCase();
};

export const joinMarketWithSeparator = (baseMarket, quoteMarket, separator) =>
  String(baseMarket + separator + quoteMarket).toUpperCase();

export const getBasePrecision = currency => {
  const config = getJSONObjectFromLocalStorage('config');
  if (config && config.currencies && config.currencies[currency]) {
    const { precision } = config.currencies[currency];
    return isNaN(precision) ? DEFAULT_CURRENCY_PRECISION : precision;
  }
  return DEFAULT_CURRENCY_PRECISION;
};

export const getWalletPrecision = currency => {
  const config = getJSONObjectFromLocalStorage('config');
  if (config && config.currencies && config.currencies[currency]) {
    const { walletPrecision } = config.currencies[currency];
    return isNaN(walletPrecision)
      ? DEFAULT_CURRENCY_PRECISION
      : walletPrecision;
  }
  return DEFAULT_CURRENCY_PRECISION;
};

export const getCurrencyPrecision = currency => {
  // This method extracts the precision from the currency object,
  // irrespective of the wallet or market

  const config = getJSONObjectFromLocalStorage('config');
  if (config && config.currencies && config.currencies[currency]) {
    const { precision } = config.currencies[currency];
    return isNaN(precision) ? DEFAULT_CURRENCY_PRECISION : precision;
  }
  return DEFAULT_CURRENCY_PRECISION;
};

export const getQuotePrecision = (base, quote) => {
  const config = getJSONObjectFromLocalStorage('config');
  if (
    base &&
    quote &&
    config &&
    config.currencies &&
    config.currencies[base] &&
    config.currencies[base].quotePrecisions
  ) {
    const precision = config.currencies[base].quotePrecisions[quote];
    return isNaN(precision) ? DEFAULT_CURRENCY_PRECISION : precision;
  }
  return DEFAULT_CURRENCY_PRECISION;
};

export const getPriceFromCurrencyPrices = (
  sourceCurrency,
  targetCurrency,
  marketPrices
) => {
  if (sourceCurrency === targetCurrency) return new Big(1);
  const bigZero = new Big(0);
  try {
    const sourceMarket = marketPrices.get(sourceCurrency);
    if (!sourceMarket || EMPTY_VALUES.indexOf(sourceMarket) > -1) {
      return bigZero;
    }
    const targetPrice = sourceMarket.get(targetCurrency);
    if (!targetPrice || EMPTY_VALUES.indexOf(targetPrice) > -1) {
      return bigZero;
    }
    return new Big(targetPrice);
  } catch (e) {
    return bigZero;
  }
};

export const normalizePaymentOptionsById = data => {
  const paymentModes = {};
  data.forEach(item => {
    paymentModes[item.id] = item;
  });
  return paymentModes;
};

export const normalizePaymentOptionsByProvider = data => {
  const paymentModes = {};
  data.forEach(item => {
    if (item.currency) {
      let currency = paymentModes[item.currency] || {};
      if (currency[item.provider]) {
        currency[item.provider].push(item);
      } else {
        currency[item.provider] = [item];
      }
      paymentModes[item.currency] = currency;
    }
  });
  return paymentModes;
};

export const getCurrencyFromType = type => {
  const config = getJSONObjectFromLocalStorage('config');
  return config && config.currencies && config.currencies[type];
};

export const getColorFromCurrency = type => {
  const currency = getCurrencyFromType(type);
  const { code = Math.random() } = currency || {};
  // Multiplying the unique currency code with the highest prime number
  // under 10000 (9973) and golden ratio (0.618033988749895) to create
  // unique colour and also to create enough space between colours of
  // consecutive currency codes.
  const vHue = (code * 9973 * 0.618033988749895) % 360;
  return `hsl(${vHue}, 70%, 65%)`;
};

export const normalizeTickerQuoteCurrency = markets => {
  return uniqBy(keys(markets).map(currency => markets[currency].quoteAsset));
};

export const mergeGraphData = (current, old) => {
  if (old[0].date > current[current.length - 1].date) {
    return current.concat(old);
  } else {
    // There is a rare case in which the current and the new data may have an overlap, which was causing the chart library to throw an exception because of unsorted array being pushed to it. Following logic just sorts the array and returns it.
    const result = [];
    let i = 0,
      j = 0;
    while (i < current.length && j < old.length) {
      if (current[i].date < old[j].date) {
        result.push(current[i]);
        i++;
      } else {
        result.push(old[j]);
        j++;
      }
    }

    while (j < old.length) {
      if (old[j].date > result[result.length - 1].date) result.push(old[j++]);
      else j++;
    }

    while (i < current.length) {
      if (current[i].date > result[result.length - 1].date)
        result.push(current[i++]);
      else i++;
    }

    return result;
  }
};

export const getBanksIconUrl = (bankIfscCode, size = 32) =>
  `https://media.wazirx.com/media/banks/${bankIfscCode}-${size}.png`;

export const normalizeMarketStatus = marketStatuses => {
  const assets = marketStatuses.assets.map(asset => {
    if (asset.networkList) {
      const validNetworks = [];
      asset.networkList.forEach(network => {
        const shouldHide = network.hidden.withdraw && network.hidden.deposit;
        if (!shouldHide) {
          validNetworks.push(network);
        }
      });
      asset.networkList = validNetworks;
    }
    return asset;
  });
  marketStatuses.assets = assets;
  return marketStatuses;
};

export const convertCurrency = (
  value,
  marketPrices,
  sourceCurrency,
  targetCurrency
) => {
  if (!value || EMPTY_VALUES.indexOf(value) > -1) {
    return BIG_ZERO;
  }

  if (isBigNumber(value)) {
    const priceCoeff = getPriceFromCurrencyPrices(
      sourceCurrency,
      targetCurrency,
      marketPrices
    );
    return priceCoeff.times(value);
  }

  return BIG_ZERO;
};
